
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Poppins";  
}

button{
  height: 40px !important;
  border-radius: 3 !important;
  box-shadow: none;
}

input, select{
  height: 40px !important;
  border-radius: 3 !important;
  width: 100%;
  padding-left: 0.5rem;
}

table{
  border: 1px solid grey;
  font-size: 0.9rem;
  font-weight: 500;
  scroll-behavior: auto;
}

table i{
  font-size: 1.2em;
  color: grey;
}
.brandName{
  color: aquamarine;
}
.appBg{
  background-color:white;
}

@media screen and (max-width: 992px) {
  .column {
    flex: 100% !important; /* !important so that it overrides the inline style because by default it has higher priority */
  }
}